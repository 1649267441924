import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import kebabCase from 'lodash/kebabCase'
import MainLayout from '../layouts'
import PostsListHeader from '../components/PostsListHeader'

import config from '../../config'

const Content = styled.div`
  text-align: center;
`

const Title = styled.h4`
  position: relative;
  text-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  margin-right: 2rem;
  display: inline-block;
`

const Category = ({
  data: {
    allMdx: { group },
  },
}) => (
  <MainLayout>
    <Helmet title={`Categories | ${config.siteTitle}`} />
    <Content>
      <PostsListHeader>Categories</PostsListHeader>
      {group.map(category => (
        <Title key={category.fieldValue}>
          <Link to={`/categories/${kebabCase(category.fieldValue)}`}>{category.fieldValue}</Link> ({category.totalCount}
          )
        </Title>
      ))}
    </Content>
  </MainLayout>
)

export default Category

Category.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      group: PropTypes.array.isRequired,
    }),
  }).isRequired,
}

export const postQuery = graphql`
  query CategoriesPageQuery {
    allMdx {
      group(field: frontmatter___categories) {
        fieldValue
        totalCount
      }
    }
  }
`
